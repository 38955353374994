import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import Markdown from 'components/markdown';
import Button from 'components/button';
import ArticleCard from 'components/article-card';
import { Row } from 'components/layout';

import DocumentationDetail from './DocumentationDetail';

import s from './Documentation.scss';

export default class documentation extends PureComponent {

  static propTypes = {
    isGlobalSite: PropTypes.bool,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    heading: PropTypes.string,
    text: PropTypes.string,
    cta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    docsAvailable: PropTypes.array,
    docsComing: PropTypes.array,
    examples: PropTypes.array,
  }

  static defaultProps = {
    isGlobalSite: true,
    heading: null,
    text: null,
    image: null,
    docsAvailable: null,
    docsComing: null,
    examples: null,
  }

  render() {
    const { image, heading, text, cta, docsAvailable, docsComing, examples, isGlobalSite } = this.props;

    return (
      <div className={s.documentation}>
        {image && image.src &&
          <div className={s.documentation__image}>
            <Image
              src={image.src}
              alt={image.alt}
              width={image.width}
              height={image.height}
            />
          </div>
        }
        <div className={s.documentation__content}>
          {heading && <h2 className={s.documentation__heading}>{heading}</h2>}
          <Markdown source={text} className={s.documentation__text} />
          {cta && cta.url && (
          <div className={s.documentation__cta}>
            <Button to={cta.url} color="red">{cta.text}</Button>
          </div>
          )}
        </div>

        {(docsAvailable || docsComing) &&
          <div className={s.documentation__content}>
            <h3 className={s.documentation__contentHeading}>{isGlobalSite ? 'Documentation' : '开发文档'}</h3>
            <div className={s.documentation__detail}>

              {docsAvailable && (
                <DocumentationDetail heading={isGlobalSite ? 'Available now' : '立即查看'} list={docsAvailable} />
              )}

              {docsComing && (
                <DocumentationDetail heading={isGlobalSite ? 'Coming soon' : '敬请期待'} list={docsComing} />
              )}
            </div>
          </div>
        }

        {examples &&
          <div className={s.documentation__content}>
            <h3 className={s.documentation__contentHeading}>{isGlobalSite ? 'Examples' : '应用实例'}</h3>
            <Row>
              {examples.map(example => (
                <div className={s.examples__example} key={example.heading}>
                  <ArticleCard
                    isGlobalSite={isGlobalSite}
                    source={example.topic}
                    heading={example.heading}
                    link={example.link}
                  />
                </div>
              ))}
            </Row>
          </div>
        }
      </div>
    );
  }
}
